$engie-blue: #009de9;
$engie-blue-allports: #0b74c0;
$engie-light-grey: #e0e0e0;
$engie-dark-grey: #757575;
$engie-light-blue: #ecf9ff;
$engie-danger: #cc0033;
$engie-orange: #e07602;
$engie-green: #009934;

$grey-dark: #202020;
$grey-medium: #5a5a5a;
$grey-light: #8f8f8f;

/* Shades of Grey */
$grey6: #f8f8f8;
$grey5: #f0f0f0;
$grey4: #d0d0d0;
$grey3: #c0c0c0;
$grey2: #b0b0b0;
$grey1: #a0a0a0;
