@use "~@angular/material" as mat;
@import "./variables";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$wtg-data-backoffice-primary: mat.define-palette(mat.$indigo-palette);
$wtg-data-backoffice-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$wtg-data-backoffice-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$wtg-data-backoffice-theme: mat.define-light-theme(
  (
    color: (
      primary: $wtg-data-backoffice-primary,
      accent: $wtg-data-backoffice-accent,
      warn: $wtg-data-backoffice-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($wtg-data-backoffice-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Lato, sans-serif !important;
}

.ant-card-actions {
  background: white !important;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.bg-engie-blue {
  background-color: $engie-blue;
}
.text-engie-blue {
  color: $engie-blue;
}
.text-engie-danger {
  color: $engie-danger;
}
.text-engie-green {
  color: $engie-green;
}
.text-engie-orange {
  color: $engie-orange;
}
.hover-text-engie-blue:hover {
  color: $engie-blue-allports;
}
.hover-bg-engie-blue:hover {
  background-color: $engie-blue-allports;
}
.border-engie-blue {
  border-color: $engie-blue;
}
.border-engie-danger {
  border-color: $engie-danger;
}
.ant-table-content {
  overflow: auto !important;
}
